import React from 'react';
import ProjectThumbnail from './../Components/Project-Thumbnail.js';
import { Link } from 'react-router-dom';

function TechPortfolio(props) {
	return (
		<div>
			<h2 className="breadcrumbs">
				<Link to="/portfolio">Portfolio</Link> &gt; Technical
			</h2>
			
			<div className="project-grid">
				<ProjectThumbnail 
					link="https://github.com/cs-440-at-uic/442-Group-3-Spring-2020/commits?author=LaLaLottie&since=2020-04-01&until=2020-05-01"
					image="/Images/Fridaythe13thShot.png"
					imageAlt="Friday the 13th Game Screenshot"
					title="Friday the 13th Game"
					desc="A class group project where the objective is to escape from the enemy."
					category="Technical"
					subcategory=" Software / Gaming"
				/>

				<ProjectThumbnail 
					link="https://github.com/LaLaLottie/lalalottie.github.io"
					image="/Images/PortfolioShot.png"
					imageAlt="Personal Site of Charlotte Norman"
					title="Personal Website"
					desc="A site to showcase myself and my portfolio."
					category="Technical"
					subcategory="Web"
				/>

				<ProjectThumbnail 
					link="https://github.com/LaLaLottie/MapsWithFriends"
					image="/Images/MapsWithFriendsShot.jpg"
					imageAlt="Maps With Friends Code Screenshot"
					title="Maps With Friends"
					desc="A location-based game using Google Maps."
					category="Technical"
					subcategory="Software / Gaming"
				/>

				<ProjectThumbnail 
					link="https://github.com/LaLaLottie/ExamBuilder"
					image="/Images/ExamBuilderShot.png"
					imageAlt="Exam Builder Game Screenshot"
					title="Exam Builder"
					desc="A exam building program that can be by instructors and students."
					category="Technical"
					subcategory="Software / General"
				/>

				<ProjectThumbnail 
					link="https://github.com/LaLaLottie/ArduinoPingPong"
					image="/Images/PingPongShot.jpg"
					imageAlt="Arduino Ping Pong Game Setup"
					title="Arduino Ping Pong Game"
					desc="PA class group project to build a ping pong game using Arduino."
					category="Technical"
					subcategory="Software / Gaming"
				/>

			</div>
		</div>
	);
}

export default TechPortfolio;