import React from 'react';

function Home(props) {
	return (
		<div className="home">
			<h3>Full Stack Developer &amp; Aspiring Animator</h3>

			<div className="home-section">
				<div className="home-description">
					<h3>Web Development</h3>
					<p>Responsive and modern sites from small single-page sites to robust, multifaceted web applications.</p>
				</div>
				<img src={process.env.PUBLIC_URL + "/Images/code-on-laptop.jpeg"} alt="Code on a Laptop" />
			</div>

			<div className="home-section">
				<div className="home-description">
					<h3>Software Solutions</h3>
					<p>Experienced in software technical consulting with clients ranging from start ups to large enterprises.</p>
				</div>
				<img src={process.env.PUBLIC_URL + "/Images/sticky-notes.jpeg"} alt="Sticky Notes on a White Board" />
			</div>

			<div className="home-section">
				<div className="home-description">
					<h3>Gaming</h3>
					<p>Interested in pursuing development in gaming and animation.</p>
				</div>
				<img src={process.env.PUBLIC_URL + "/Images/sketchbook.jpeg"} alt="Sketchbook and Pens/Pencils" />
			</div>

		</div>
	);
}

export default Home;