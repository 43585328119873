import React from 'react';
import logo from './Images/tree-logo-white.png';

// Import Components
import { /*HashRouter, */ BrowserRouter, Route, Switch, NavLink } from 'react-router-dom';

import Home from './Pages/Home.js';
import About from './Pages/About.js';
import Portfolio from './Pages/Portfolio.js';
import Blog from './Pages/Blog.js';
import Contact from './Pages/Contact.js';
import TechPortfolio from './Pages/Portfolio-Technical.js';
import ArtPortfolio from './Pages/Portfolio-Art.js';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <div className="header">
          
          <div className="header-title">
            <img src={logo} className="logo-image" alt="Logo" />
            <h1>Charlotte Norman</h1>
          </div>

          {/* Navigation */}
          <div className="navigation">
            <div className="sub-navigation">
              <NavLink exact to="/" className="item white-link" activeClassName="current">Home</NavLink>
              <NavLink to="/about" className="item white-link" activeClassName="current">About</NavLink>
              <NavLink to="/portfolio" className="item white-link" activeClassName="current">Portfolio</NavLink>
              {/*<NavLink to="/blog" className="item" activeClassName="current">Blog</Link>*/}
              <NavLink to="/contact" className="item white-link" activeClassName="current">Contact</NavLink>
            </div>
          </div>
        </div>

        <div className="sub-header-banner">
            <h1>I create sites and applications.</h1>
        </div>

        <div className="App-body">

          <div className="main-content">
            {/* Setting Up Routes */}
            <Switch>
              <Route exact path="/" component={Home} /> 
              <Route path="/about" component={About} />
              <Route exact path="/portfolio" component={Portfolio} />
              <Route path="/blog" component={Blog} />
              <Route path="/contact" component={Contact} />
              <Route exact path="/portfolio/technical" component={TechPortfolio} />
              <Route exact path="/portfolio/art" component={ArtPortfolio} />
              {/* Route for 404 */}
            </Switch>
          </div>

          <div className="side-content">
            <p>Copyright</p> <p>@ Charlotte Norman</p>
          </div>

        </div>
        <div className="footer">
          <p>Charlotte Norman © 2021</p>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
