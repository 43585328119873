import React from 'react';
// import ProjectThumbnail from './../Components/Project-Thumbnail.js';
import { Link } from 'react-router-dom';

function ArtPortfolio(props) {
	return (
		<div>
			<h2 className="breadcrumbs">
				<Link to="/portfolio">Portfolio</Link> &gt; Art
			</h2>
			
			<div className="pageUnderConstruction">
				<h2>Page Under Construction</h2>

				<img src={process.env.PUBLIC_URL + "/Images/noun_website_under_construction.png"} alt="Page Under Construction" />
			</div>
		</div>
	);
}

export default ArtPortfolio;