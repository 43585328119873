import React from 'react';

import './../App.css';

function ProjectThumbnail(props) {
	return (
		<a href={props.link} target="_blank" className="project-link" rel="noopener noreferrer">
			<div className="project-tile">
				<div className="project-image-container">
					<img className="project-image" src={process.env.PUBLIC_URL + props.image} alt={props.imageAlt} />
				</div>
				<div className="project-details">
					<div className="project-title">{props.title}</div>
					<div className="project-description">{props.desc}</div>
					<div className="project-category">> {props.category} / {props.subcategory}</div>	
				</div>	
			</div>
		</a>
	);
}

export default ProjectThumbnail;