import React from 'react';
import HeaderThumbnail from './../Components/Header-Thumbnail.js';

function Portfolio(props) {
	return (
		<div>
			<h1 className="page-title">Portfolio</h1>

			<div className="portfolio">
				<HeaderThumbnail
					link="/portfolio/technical"
					title="Technical"
					desc="Programming Projects"
					image="/Images/noun_programming.png"
					imageAlt="Technical Portfolio Icon"
				/>

				<HeaderThumbnail
					link="/portfolio/art"
					title="Art"
					desc="Art Pieces"
					image="/Images/noun_art_tablet.png"
					imageAlt="Art Portfolio Icon"
				/>
			</div>
		</div>
	);
}

export default Portfolio;