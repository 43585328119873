import React from 'react';

function Blog(props) {
	return (
		<div>
			<h1 className="page-title">Blog</h1>
			<h2 className="blog-tagline">Under Construction</h2>
		</div>
	);
}

export default Blog;