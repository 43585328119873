import React from 'react';
import {Link} from 'react-router-dom';

import './../App.css';

function HeaderThumbnail(props) {
	return (
		<Link to={props.link} className="header-link">	
			<div className="header-tile">			
				<div className="header-title">{props.title}</div>
				<div className="header-description">{props.desc}</div>
				<img src={process.env.PUBLIC_URL + props.image} alt={props.imageAlt} />		
			</div>
		</Link>
	);
}

export default HeaderThumbnail;