import React from 'react';
import { useState } from 'react';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
/* import FormControl from 'react-bootstrap/FormControl';
import FormCheck from 'react-bootstrap/FormCheck';
import InputGroup from 'react-bootstrap/InputGroup'; */

import Alert from 'react-bootstrap/Alert';

function Contact(props) {
	const [validated, setValidated] = useState(false);
	const [fromName, setFromName] = useState("");
	const [fromEmail, setFromEmail] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showFailAlert, setShowFailAlert] = useState(false);

	const handleSubmission = event => {
		const form = event.currentTarget;

		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();

			alert("Email was not sent due to invalidation errors.");
		}
		else {
			event.preventDefault();

			// Sending Email

			window.emailjs.sendForm('default_service', 'template_7RaqsIcG', event.target)
				.then(function(response) {
					console.log("SUCCESS!", response.status, response.text);
					setShowSuccessAlert(true);
					setFromName("");
					setFromEmail("");
					setSubject("");
					setMessage("");
					setValidated(true);

				}, function(error) {
					console.log("FAILED...", error);
					setShowFailAlert(true);
					setValidated(false);
				});
		}

		console.log('Validated:', validated);
	};
	return (
		<div>
			<Alert show={showSuccessAlert} variant="success" onClose={() => setShowSuccessAlert(false)} dismissible>
			Your message has been successfully submitted!</Alert>

			<Alert show={showFailAlert} variant="danger" onClose={() => setShowFailAlert(false)} dismissible>
			Your message has not submitted.</Alert>

			<div className="contact-content">
				<div className="social-media-container">
					<h1 className="page-title">Contact Me</h1>
					<h2>Check out my profiles and let's connect!</h2>

					<div className="icon-carousel">
						<div className="sm-icon-container"> 
							<a href="https://www.linkedin.com/in/charlotte--norman/" className="icon-link" target="_blank" rel="noopener noreferrer">
								<img src={process.env.PUBLIC_URL + "/Images/LinkedIn-Logos/LI-In-Bug-Black.png"} alt="LinkedIn" />
								<p>LinkedIn</p>
							</a>
						</div>
						<div className="sm-icon-container">
							<a href="https://github.com/LaLaLottie" className="icon-link" target="_blank" rel="noopener noreferrer">
								<img src={process.env.PUBLIC_URL + "/Images/GitHub-Mark/PNG/GitHub-Mark-120px-plus.png"} alt="GitHub" />
								<p>GitHub</p>
							</a>
						</div>
					</div>
				</div>

				<div id="contactForm" className="contact-form">
					<Form validate="yes" onSubmit={handleSubmission}>
						<Form.Row>
							<Col>
								<Form.Group size="" controlId="contactFormName"> 
									<Form.Label>Name*</Form.Label>
									<Form.Control size="lg" 
										required
										custom="true"
										name="fromName"
										type="text"
										minLength="4"
										placeholder="First Name Last Name"
										value={fromName}
										onChange={ e => setFromName(e.target.value) }
									/>
								</Form.Group>
								<Form.Group size="" controlId="contactFormEmail"> 
									<Form.Label>Email*</Form.Label>
								    {/* <InputGroup sm size="sm"> 
										<InputGroup.Prepend>
											<InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
										</InputGroup.Prepend>
									</InputGroup> */}
									<Form.Control size="lg"
										required
										custom="true"
										name="fromEmail"
										type="email"
										placeholder="name@example.com"
										value={fromEmail}
										onChange={ e => setFromEmail(e.target.value) }
									/>
								</Form.Group>
								<Form.Group size="" controlId="contactFormSubject">
									<Form.Label>Subject*</Form.Label>
									<Form.Control size="lg"
										required
										custom="true"
										name="subject"
										type="text"
										placeholder=""
										value={subject}
										onChange={ e => setSubject(e.target.value) }
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group size="" controlId="contactFormMessage">
									<Form.Label>Message*</Form.Label>
									<Form.Control size="lg"
										required
										custom="true"
										name="message"
										as="textarea"
										placeholder="Please type your message here..."
										value={message}
										onChange={ e => setMessage(e.target.value) }
										rows="12"
									/>
								</Form.Group>
							</Col>
						</Form.Row>
						<Button type="submit" variant="light" size="lg">Send</Button>
					</Form>

				</div>
			</div>
		</div>
	);
}

export default Contact;