import React from 'react';
import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';

function About(props) {
	const [creditsToggleOn, setCreditsToggleOn] = useState(false);

	return (
		<div>
			<h1 className="page-title">Charlotte Norman</h1>

			<h2 className="tagline">In Sight</h2>

			<div className="image-carousel"></div>

			<div className="about-description">
				<p>Hello, I'm a software developer and aspiring animator based in Chicago, IL, USA.  My recent focus has been general web development and I have experience in creating sites/web applications through, but not limited to, Node.js, React JS, Spring Boot, and SharePoint. From popular content management systems like Wordpress to custom solutions, I'm well versed in web development, but also desires to continue work in the backend and other types of software.  I'm a full stack programmer working to venture more into gaming and/or animation.</p>

				<p>I studied Computer Science and Political Science at the University of Illinois at Chicago. Avid sketch-ist, amateur cook, bookworm, and random-facts-knower.</p> 

				<div className="resume">
					<img src={process.env.PUBLIC_URL + "/Images/noun_download.png"} alt="Charlotte's Resume"/><a href={process.env.PUBLIC_URL + "/CharlotteNormanResume.pdf"} download="CharlotteNormanResume.pdf">Resume</a>
				</div>
			</div>

			<div className="credits-container">
				<h4 class="credits-btn" onClick={() => {console.log(creditsToggleOn); setCreditsToggleOn(true)}}>Image Credits</h4>

				<CSSTransition
					in={creditsToggleOn}
					classNames="credits"
					timeout={300}
					unmountOnExit
					onExited={() => setCreditsToggleOn(false)}
				>
					
					<Alert
						variant="primary"
						dismissible
						onClose={() => setCreditsToggleOn(false)}
						
					>
						<div className="credits" autoFocus>		
							<p>Logos featured on this site, unless otherwise specified, were not created or owned by Charlotte Norman.  They are credited down below and used non-commercially under a Creative Commons license.</p> 

							<ul>
								<li><a href="https://thenounproject.com/" target="_blank" rel="noopener noreferrer">https://thenounproject.com/</a></li>
									<ul>
										<li>programming by ibrandify from the Noun Project</li>
										<li>modern art by Vectors Point from the Noun Project</li>
										<li>Download by Mata Sapi from the Noun Project</li>
										<li>website under construction by john melven from the Noun Project</li>
									</ul>
								<li><a href="https://unsplash.com/" target="_blank" rel="noopener noreferrer">https://unsplash.com/</a></li>
									<ul>
										<li>Sketchbook by Jessica Lewis</li>
										<li>White Board by Daria Nepriakhina</li>
										<li>Laptop by Clément Hélardot</li>	
										<li>Laptop by Tianyi Ma</li>	
									</ul>
								<li><a href="https://github.com/logos" target="_blank" rel="noopener noreferrer">GitHub Logos</a></li>
								<li><a href="https://brand.linkedin.com/downloads" target="_blank" rel="noopener noreferrer">LinkedIn Logos</a></li>
								<li><a href="https://www.vectorstock.com/royalty-free-vector/tree-logo-vector-20943620" target="_blank" rel="noopener noreferrer">Tree Logo at VectorStock</a></li>
							</ul>
						</div>
					</Alert>
				</CSSTransition>
			</div>
		</div>
	);
}

export default About;